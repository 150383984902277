<template>
    <div>
        <div class="productsModal">
            <b-modal :title="$t('keys.products.product')" v-model="modalActive" no-close-on-backdrop size="lg">

                <template #modal-header>
                    <b-tabs-header :tabs="getTitles" @clickTab="handleClickTab" :activeTab.sync="activeTab" @close="modalActive = false" />
                </template>
                <template #default>
                    <div>
                        <b-overlay :show="showLoader" >
                            <validation-observer ref="validation">
                                <div v-show="activeTab === 0" :title="getTabTitle">
                                    <b-form-group :label="$t('form_data.name')">
                                        <validation-provider #default="{ errors }" :name="$t('form_data.name')"
                                                             rules="required">
                                            <b-form-input ref="name" autofocus v-model="addObject.name"/>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                    <b-form-group :label="$t('general.category')">
                                        <validation-provider #default="{ errors }" :name="$t('general.category')"
                                                             rules="required">
                                            <v-select v-model="addObject.product_category_id" appendToBody label="name"
                                                      :options="productSubcategories" :reduce="item => item.id"/>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                    <b-form-group :label="$t('general.additions')">
                                        <v-select v-model="addObject.order_option_group_ids" appendToBody label="name" :options="productOrderOptions" :reduce="item => item.id" multiple/>
                                    </b-form-group>
                                    <b-form-group :label="$t('course_menu.course_menu')" v-if="course_menus && course_menus.length > 0 && courseMenuConfiguration && courseMenuConfiguration.course_menu_enabled && $hasTierPermission(Tiers.Premium)">
                                        <v-select v-model="addObject.course_menu_id" appendToBody label="name" :options="course_menus" :reduce="item => item.id" />
                                    </b-form-group>
                                    <b-form-group v-if="!editing" :label="$t('keys.sale_price')">
                                        <div class="d-inline-flex w-100">
                                            <div>
                                                <b-input-group-prepend is-text class="checkboxPrice">
                                                    <b-form-checkbox v-model="salePriceEnabled" @change="changeNoPredefinedPrice"/>
                                                </b-input-group-prepend>
                                            </div>
                                            <div class="w-100">
                                                <validation-provider #default="{ errors }" :name="$t('keys.sale_price')" :rules="salePriceEnabled ? `required|decimalCustom:2${addObject.allow_negative_price ? '' : '|mustBePositive:true'}` : '' ">
                                                    <b-input-group class="inputPrice" append="€">
                                                        <b-form-input :disabled="!salePriceEnabled" type="number" :value="salePrice" v-on:input="changeSalePrice"/>
                                                    </b-input-group>
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </div>
                                        </div>
                                    </b-form-group>
                                    <div class="d-flex mb-1" v-else>
                                        <div>{{$t("keys.sale_price")}}</div>
                                        <b-form-checkbox
                                            @change="changeNoPredefinedPrice"
                                            class="ml-2"
                                            v-model="salePriceEnabled"
                                        />
                                    </div>
                                    <div class="d-flex mb-1" v-if="showSalePrice && !initialPredefinedPrice && editing">
                                        <b-alert show variant="warning" class="p-1">
                                            {{$t('table_fields.current_price')}} <b>{{originalSalePrice}}€</b>, {{$t('table_fields.if_you_want_to_change')}}
                                        </b-alert>
                                    </div>
                                    <div class="d-flex mb-1">
                                        <div>{{$t("table_fields.entered_price_can_be_negative")}}</div>
                                        <b-form-checkbox
                                            switch
                                            class="ml-2 red"
                                            v-model="addObject.allow_negative_price"
                                        />
                                    </div>
                                    <b-row>
                                        <b-col>
                                            <validation-provider #default="{ errors }" :name="$t('keys.color')">
                                                <colorSelector  v-model="addObject.color" :product-name="addObject.name"></colorSelector>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-col>
                                    </b-row>
                                    <div class="d-flex">
                                        <div>{{$t("keys.products.disabled")}}</div>
                                        <b-form-checkbox
                                            switch
                                            class="ml-2 red"
                                            v-model="addObject.disabled"
                                        />
                                    </div>
                                    <div v-if="editing && Object.keys(businessUnitsWithNegativePrice).length > 0">
                                        <b-alert show variant="warning" class="p-1 mt-2">
                                            <p class="mb-1">{{$t('keys.products.has_negative_price')}}</p>
                                            <p v-for="(units, name) in businessUnitsWithNegativePrice" :key="name">
                                                {{name}}
                                                <ul class="mt-1">
                                                    <li v-for="unit in units" :key="unit.price_list_name">
                                                        <span v-if="unit.price_list_name !== ''">
                                                            {{ unit.price_list_name }} → {{$numberRounding(unit.price, 2)}}€
                                                        </span>
                                                        <span v-else>
                                                            {{$t('reporting.default_price_list')}} → {{$numberRounding(unit.price, 2)}}€
                                                        </span>
                                                    </li>
                                                </ul>
                                            </p>
                                            <p>{{$t('keys.products.first_set_price')}}<router-link to="price-list">{{$t('keys.here')}}</router-link></p>
                                        </b-alert>
                                    </div>
                                </div>
                                <div v-show="activeTab === 1 && $hasTierPermission(Tiers.Standard, Tiers.Premium)">
                                    <b-form-group :label="$t('general.add_ingredient')">
                                        <b-row>
                                            <b-col cols="6">
                                                <validation-provider #default="{ errors }"
                                                                     :name="$t('general.add_ingredient')">
                                                    <v-select label="name" :filter="fuseSearch" v-model="selected_ingredient"
                                                              appendToBody :options="newIngredientsFiltered"
                                                              @option:selected="ingredientChange"
                                                              :selectable="(ingredient) => !ingredient.disabled">
                                                        <template #option="{ name, ingredient_code, disabled }">
                                                            <div :class="{'disabledIngredient': disabled}">{{ ingredient_code }} - {{ name }}</div>
                                                        </template>

                                                        <template #selected-option="{ name, ingredient_code }">
                                                            <div>{{ ingredient_code }} - {{ name }}</div>
                                                        </template>
                                                    </v-select>
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-col>
                                            <b-col class="p-0" cols="4">
                                                <b-button class="w-100" variant="primary" @click="$refs.addModal.open()">
                                                    <feather-icon icon="PlusIcon" class="mx-50"/>
                                                    {{ $t('general.add_ingredient') }}
                                                </b-button>
                                            </b-col>
                                            <b-col class="p-0 pl-3" cols="2">
                                                <b-button  variant="outline-primary" @click="cloneIngredient(selected_ingredient)" :disabled="!selected_ingredient">
                                                    <feather-icon icon="CopyIcon" class="mx-50"/>
                                                </b-button>
                                            </b-col>
                                        </b-row>
                                    </b-form-group>

                                    <b-form-group :label="$t('form_data.quantity')">
                                        <div style="display: flex; width: 100%">
                                            <validation-provider style="width: 100%" #default="{ errors }"
                                                                 :name="$t('form_data.quantity')" :rules="getQuantityRules">
                                                <b-form-input v-model="selected_quantity"/>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                            <validation-provider #default="{ errors }"
                                                                 :name="$t('form_data.quantity')+'-select'">
                                                <v-select style="width: 150px; margin-left: 1rem"
                                                          v-model="selected_amount_type" :disabled="!selected_ingredient"
                                                          appendToBody label="name" :options="amount_types"/>
                                                <small style="width: 150px" class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </div>
                                    </b-form-group>
                                    <div class="d-flex justify-content-center ">
                                        <b-button variant="primary" @click="addIngredient" class="w-25">
                                            <feather-icon icon="ArrowDownIcon" class=" justify-content-center"/>
                                        </b-button>
                                    </div>
                                    <div v-if="addObject.ingredients">
                                        <div>{{$t('general.ingredients')}}</div>
                                        <Table ref="ingredientsTable" name="ingredients" class="mt-1" :columnDefs="columnDefs"
                                               :rowData="addObject.ingredients" @deleteRow="removeIngredient"
                                               @edit="editIngredient"/>
                                    </div>

                                    <div  v-if="editing">
                                        <b-button variant="primary"  block @click="isCollapseOpen = !isCollapseOpen" class="mb-1 mt-3">{{$t("products.update_ingredient_stock_from_date")}}</b-button>
                                        <b-collapse v-model="isCollapseOpen" role="tabpanel">
                                            <div class="text-bold cardBackground p-2" style="border-radius: 18px;">

                                                <b-form-group :label="$t('general.date_from')">
                                                    <date-picker :locale="currentLocale" :first-day-of-week="2" :model-config="modelConfig" :popover="popoverOptions"  popover-direction="top" class="relative w-100" v-model="dateToRegenerateIngredients" :masks="{input: ['DD. MM. YYYY'], data: ['DD. MM. YYYY']}">
                                                        <template v-slot="{ inputValue, inputEvents }">
                                                            <input class="form-control"
                                                                   :value="inputValue"
                                                                   v-on="inputEvents"
                                                            />
                                                        </template>
                                                    </date-picker>
                                                </b-form-group>
                                                <div class="d-flex justify-content-end">
                                                    <b-button class="mt-1" variant="primary" :disabled="dateToRegenerateIngredients === null" @click="recalculateStock">
                                                        {{$t("products.calculate_again")}}
                                                    </b-button>
                                                </div>
                                            </div>
                                        </b-collapse>
                                    </div>

                                </div>
                                <div v-if="activeTab === 2">
                                    <b-form-group label="Omejitev popusta">
                                        <div class="d-inline-flex w-100">
                                            <div>
                                                <b-input-group-prepend is-text class="checkboxPrice">
                                                    <b-form-checkbox v-model="maxDiscountEnabled" @change="changeMaxDiscountEnabled"/>
                                                </b-input-group-prepend>
                                            </div>
                                            <div class="w-100">
                                                <validation-provider #default="{ errors }" :name="$t('keys.sale_price')" :rules="maxDiscountEnabled ? 'required|decimalCustom:2|mustBePositive:true|max_value:100' : '' ">
                                                    <b-input-group class="inputPrice" append="%">
                                                        <b-form-input :disabled="!maxDiscountEnabled" type="number" :value="maxDiscount" v-on:input="changeMaxDiscount"/>
                                                    </b-input-group>
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </div>
                                        </div>
                                    </b-form-group>
                                </div>
                            </validation-observer>
                        </b-overlay>
                    </div>
                </template>

                <template #modal-footer>
                    <b-button variant="primary" @click="validate" :disabled="showLoader">
                        <span v-if="editing">
                            <feather-icon icon="EditIcon" class="mr-50"/>
                            <span class="align-middle">{{ $t('general.edit') }}</span>
                        </span>
                        <span v-else>
                            <feather-icon icon="PlusIcon" class="mr-50"/>
                            <span class="align-middle">{{ $t('general.save') }}</span>
                        </span>
                    </b-button>
                </template>
            </b-modal>
        </div>
        <AddIngredient ref="addModal" :ingredients="ingredients" v-on:itemAdded="ingredientAdded" :ingredient_categories="ingredient_categories"/>
    </div>
</template>
<script>
    import Fuse from 'fuse.js'
    import {BButton, BFormGroup, BFormInput, BModal, BRow, BCol, BFormCheckbox, BOverlay, BInputGroup, BCollapse, BInputGroupPrepend, BAlert} from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, minValue, mustBePositive } from '@validations'
    import vSelect from 'vue-select'
    import Table from '@/views/components/Table/Table'
    import AddIngredient from '@/views/Ingredient/AddIngredient.vue'
    import ColorSelector from '@/views/ColorSelector/ColorSelector.vue'
    import * as Sentry from '@sentry/vue'
    import {Tiers} from '@/libs/enums/Tiers'
    import BTabsHeader from '@/views/components/BTabsHeader.vue'
    import {DatePicker} from 'v-calendar'
    import {
        getMoneyTypes,
        getPieceTypes,
        getVolumeTypes,
        getWeightTypes
    } from '@/libs/constants/ingredients.js'
    import {PackagingUnit} from '@/libs/enums/PackagingUnit'

    export default {
        components: {
            BTabsHeader,
            AddIngredient,
            BInputGroup,
            BInputGroupPrepend,
            BFormGroup,
            BFormInput,
            BModal,
            BButton,
            BRow,
            BCol,
            BFormCheckbox,
            ValidationProvider,
            ValidationObserver,
            vSelect,
            Table,
            ColorSelector,
            BOverlay,
            DatePicker,
            BCollapse,
            BAlert
        },
        props: {
            vats: {
                type: Array
            },
            productSubcategories: {
                type: Array
            },
            ingredients: {
                type: Array
            },
            productOrderOptions: {
                type: Array
            }
        },
        data() {
            return {
                isCollapseOpen:false,
                dateToRegenerateIngredients: null,
                activeTab: 0,
                canAdd: false,
                clone: false,
                modalActive: false,
                newIngredientsFiltered: [],
                allIngredients: [],
                addObject: {
                    name: '',
                    sale_price: '',
                    color: '#545454',
                    vat_group_id: '',
                    product_category_id: '',
                    order_option_group_ids: [],
                    additional_options: {},
                    disabled: false,
                    ingredients: [],
                    course_menu_id: null,
                    no_predefined_price: false,
                    allow_negative_price: false,
                    additional_product_options: {
                        max_discount: '',
                        max_discount_enabled: false
                    }
                },
                required,
                minValue,
                mustBePositive,
                amount_types: [],
                salePrice: '',
                salePriceEnabled: true,
                showSalePrice: false,
                originalSalePrice: 0,
                initialPredefinedPrice: true,
                pricesInBusinessUnits: [],
                businessUnitsWithNegativePrice: [],
                maxDiscount: '',
                maxDiscountEnabled: false,
                selected_ingredient: null,
                selected_quantity: 0,
                selected_amount_type: null,
                editing: false,
                ingredient_categories: [],
                showLoader: false,
                courseMenuConfiguration:false,
                course_menus:[],
                modelConfig: {
                    type: 'string',
                    mask: 'iso'
                },
                popoverOptions: {
                    positionFixed: true
                }
            }
        },
        methods: {
            async recalculateStock() {
                try {
                    this.showLoader = true
                    const payload = {
                        product: {
                            id: this.addObject.id,
                            name: this.addObject.name,
                            color: this.addObject.color,
                            product_category_id: this.addObject.product_category_id,
                            additional_options: this.addObject.additional_options,
                            disabled: this.addObject.disabled,
                            ingredients: this.addObject.ingredients,
                            order_option_group_ids: this.addObject.order_option_group_ids,
                            course_menu_id:this.addObject.course_menu_id
                        },
                        timestamp: this.dateToRegenerateIngredients
                    }

                    this.canAdd = false
                    await this.$http.put(`/api/client/v1/products/recalculate_stock/${this.addObject.id}`, payload)
                    this.$printSuccess(this.$t('print.success.product_stock_recalculated'))
                    this.$emit('itemAdded')
                    this.addObject = {
                        name: '',
                        sale_price: '',
                        color: '#545454',
                        vat_group_id: '',
                        product_category_id: '',
                        order_option_group_ids: [],
                        disabled: false,
                        additional_options: '',
                        course_menu_id: null
                    }
                    this.salePrice = ''
                    this.modalActive = false
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                } finally {
                    this.showLoader = false
                }
            },
            handleClickTab(index) {
                this.activeTab = index
            },
            async ingredientAdded(id) {
                await this.reloadData()


                const item = this.allIngredients.find(ele => ele.id === id)

                if (item) {
                    this.selected_ingredient = item
                    this.ingredientChange()
                }


            },
            getConvertedQuantity(item) {
                if (!item || !item.quantity) return '/'

                let type = null
                if (item.selected_unit_type === PackagingUnit.Milliliter || item.selected_unit_type === PackagingUnit.Decilitre || item.selected_unit_type === PackagingUnit.Liter) {
                    type = this.volume_types.find(ele => ele.name === item.selected_unit_type)
                } else if (item.selected_unit_type === PackagingUnit.Gram || item.selected_unit_type === PackagingUnit.Decagram || item.selected_unit_type === PackagingUnit.Kilogram) {
                    type = this.weight_types.find(ele => ele.name === item.selected_unit_type)
                } else if (item.selected_unit_type === PackagingUnit.Euro) {
                    type = this.money_types.find(ele => ele.name === item.selected_unit_type)
                } else {
                    type = this.piece_types.find(ele => ele.name === item.selected_unit_type)
                }

                if (!type) {
                    return
                }

                const numberOfDecimals = Math.log10(type.factor)

                const stock = Number(Number(item.quantity) / Number(type.factor)).toFixed(numberOfDecimals).replace(`.${'0'.repeat(numberOfDecimals)}`, '')

                return `${stock} ${item.selected_unit_type}`
            },
            ingredientChange() {
                if (this.selected_ingredient.packaging_unit === PackagingUnit.Milliliter || this.selected_ingredient.packaging_unit === PackagingUnit.Decilitre || this.selected_ingredient.packaging_unit === PackagingUnit.Liter) {
                    this.selected_amount_type = this.volume_types.find(ele => ele.name === this.selected_ingredient.packaging_unit)
                    this.amount_types = this.volume_types
                } else if (this.selected_ingredient.packaging_unit === PackagingUnit.Gram || this.selected_ingredient.packaging_unit === PackagingUnit.Decagram || this.selected_ingredient.packaging_unit === PackagingUnit.Kilogram) {
                    this.selected_amount_type = this.weight_types.find(ele => ele.name === this.selected_ingredient.packaging_unit)
                    this.amount_types = this.weight_types
                } else if (this.selected_ingredient.packaging_unit === PackagingUnit.Euro) {
                    this.selected_amount_type = this.money_types.find(ele => ele.name === this.selected_ingredient.packaging_unit)
                    this.amount_types = this.money_types
                } else {
                    this.selected_amount_type = this.piece_types.find(ele => ele.name === this.selected_ingredient.packaging_unit)
                    this.amount_types = this.piece_types
                }
            },
            fuseSearch(options, search) {
                const fuse = new Fuse(options, {
                    keys: ['name', 'ingredient_code'],
                    shouldSort: true
                })
                return search.length ? fuse.search(search).map(({item}) => item) : fuse.list
            },
            cloneIngredient(data) {
                const sendData = JSON.parse(JSON.stringify(data))
                sendData.name = ''
                this.$refs.addModal.open(sendData, true)
            },
            async open(data = null, clone = null) {
                this.clone = clone
                this.loadIngredientCategories()
                if (data !== null) {
                    this.addObject = JSON.parse(JSON.stringify(data))
                    if (this.addObject.ingredients === null) {
                        this.addObject.ingredients = []
                    }
                    this.editing = !clone

                    const res = await this.$http.get(`/api/client/v1/products/product_with_all_prices/${data.id}`)
                    this.pricesInBusinessUnits = res.data ?? []

                    this.salePriceEnabled = !this.addObject.no_predefined_price
                    this.initialPredefinedPrice = this.salePriceEnabled
                    this.salePrice = this.salePriceEnabled ? JSON.parse(JSON.stringify(this.addObject.sale_price[0].sale_price)) / 1000000 : ''
                    this.originalSalePrice = JSON.parse(JSON.stringify(this.addObject.sale_price[0].sale_price)) / 1000000
                    this.showSalePrice = false
                    if (clone) {
                        this.addObject.name = ''
                        this.addObject.disabled = false
                        this.addObject.sale_price = JSON.parse(JSON.stringify(data.sale_price[0].sale_price))
                        this.addObject.ingredients = []
                        this.salePrice = JSON.parse(JSON.stringify(this.addObject.sale_price)) / 1000000
                    }
                    this.maxDiscountEnabled = this.addObject.additional_product_options.max_discount_enabled
                    this.maxDiscount = this.addObject.additional_product_options.max_discount / 100
                    this.businessUnitsWithNegativePrice = []
                } else {
                    this.editing = false
                    this.addObject = {
                        name: '',
                        sale_price: '',
                        color: '#545454',
                        vat_group_id: '',
                        product_category_id: '',
                        additional_options: {},
                        order_option_group_ids: [],
                        course_menu_id: null,
                        disabled: false,
                        ingredients: [],
                        additional_product_options: {},
                        no_predefined_price: false,
                        allow_negative_price: false
                    }
                    this.salePrice = ''
                    this.salePriceEnabled = true
                    this.maxDiscount = ''
                    this.maxDiscountEnabled = false
                }

                this.newIngredientsFiltered = this.ingredients.filter((ingredient) => {
                    return !this.addObject.ingredients.some((addedIngredient) => addedIngredient.id === ingredient.id)
                })
                this.allIngredients = this.ingredients
                this.selected_quantity = 0
                this.modalActive = true
                this.selected_ingredient = null
                this.canAdd = true
                this.activeTab = 0
            },
            async loadIngredientCategories() {

                if (!this.$hasTierPermission(Tiers.Standard, Tiers.Premium)) {
                    return
                }

                try {

                    this.showLoader = true

                    const response = await this.$http.get('/api/client/v1/ingredient_subcategories/')
                    this.ingredient_categories = response.data ?? []

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async reloadData() {
                if (!this.$hasTierPermission(Tiers.Standard, Tiers.Premium)) {
                    return
                }

                try {
                    this.showLoader = true
                    const response = await this.$http.get('/api/client/v1/ingredients/')
                    this.allIngredients = response.data ?? []
                    this.ingredients = response.data ?? []

                    this.newIngredientsFiltered = this.allIngredients.filter(elem => !this.addObject.ingredients.find(rm => (rm.id === elem.id)))

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.$refs.ingredientsTable.refresh()
                    this.showLoader = false
                }
            },
            validateIngredient() {
                this.$refs.validation.validate().then((success) => {
                    if (success) {
                        this.addIngredient()
                    }
                })
            },
            async addIngredient() {
                try {
                    const success = await this.$refs.validation.validate()

                    let tmp = this.selected_quantity

                    if (tmp) {
                        tmp = String(tmp).replace(',', '.')
                    }

                    if (success) {
                        const ingredient = {
                            'id': this.selected_ingredient.id,
                            'name': this.selected_ingredient.name,
                            'quantity': Math.round(Number(tmp) * this.selected_amount_type.factor),
                            'selected_unit_type': this.selected_amount_type.name
                        }

                        this.addObject.ingredients.push(ingredient)
                        this.newIngredientsFiltered = this.newIngredientsFiltered.filter(ele => ele.id !== this.selected_ingredient.id)

                        this.selected_ingredient = null
                        this.selected_quantity = ''
                        this.selected_amount_type = null
                        this.$refs.validation.reset()
                    }
                } catch (err) {
                    this.$printError(this.$t('print.error.on_add'))
                }


            },
            removeIngredient(id) {
                for (let i = 0; i < this.addObject.ingredients.length; i++) {
                    if (this.addObject.ingredients[i].id === id) {
                        this.newIngredientsFiltered.push(this.allIngredients.filter((ingredient) => {
                            return ingredient.id === id
                        })[0])
                        this.addObject.ingredients.splice(i, 1)
                        break
                    }
                }
            },
            editIngredient(params) {
                this.addObject.ingredients[params.rowIndex].quantity = parseInt(params.newValue)
            },
            async validate() {
                this.$refs.validation.validate().then((success) => {
                    if (success && this.canAdd === true) {
                        if (this.editing) this.edit()
                        else this.add()
                    } else {
                        this.$printError(this.$t('print.error.on_add'))
                    }
                })
            },
            async add() {
                try {
                    const payload = {
                        name: this.addObject.name,
                        sale_price: this.addObject.sale_price,
                        color: this.addObject.color,
                        text_color: this.addObject.text_color,
                        product_category_id: this.addObject.product_category_id,
                        disabled: this.addObject.disabled,
                        ingredients: this.addObject.ingredients,
                        order_option_group_ids: this.addObject.order_option_group_ids,
                        no_predefined_price: this.addObject.no_predefined_price,
                        allow_negative_price: this.addObject.allow_negative_price,
                        additional_product_options: this.addObject.additional_product_options
                    }
                    this.canAdd = false
                    const response = await this.$http.post('/api/client/v1/products/', payload)
                    this.$printSuccess(this.$t('print.success.add'))
                    this.addObject = {
                        name: '',
                        sale_price: '',
                        color: '#545454',
                        vat_group_id: '',
                        product_category_id: '',
                        additional_options: {},
                        order_option_group_ids: [],
                        disabled: false,
                        ingredients: [],
                        additional_product_options: {},
                        no_predefined_price: false,
                        allow_negative_price: false
                    }
                    this.$emit('itemAdded', response.data.id)
                    this.modalActive = false
                    this.clone = false
                    this.salePrice = ''
                    this.salePriceEnabled = true
                    this.maxDiscount = ''
                    this.maxDiscountEnabled = false
                } catch (err) {
                    this.canAdd = true
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_add'))
                }
            },
            async edit() {
                try {
                    this.businessUnitsWithNegativePrice = []
                    const payload = {
                        id: this.addObject.id,
                        name: this.addObject.name,
                        color: this.addObject.color,
                        product_category_id: this.addObject.product_category_id,
                        additional_options: this.addObject.additional_options,
                        disabled: this.addObject.disabled,
                        ingredients: this.addObject.ingredients,
                        order_option_group_ids: this.addObject.order_option_group_ids,
                        course_menu_id:this.addObject.course_menu_id,
                        additional_product_options: this.addObject.additional_product_options,
                        no_predefined_price: this.addObject.no_predefined_price,
                        allow_negative_price: this.addObject.allow_negative_price
                    }

                    if (!this.addObject.allow_negative_price && !this.addObject.no_predefined_price) {
                        this.businessUnitsWithNegativePrice = this.pricesInBusinessUnits
                            .filter(x => x.price < 0)
                            .reduce((acc, unit) => {
                                const group = acc[unit.business_unit_name] || []
                                group.push(unit)
                                acc[unit.business_unit_name] = group
                                return acc
                            }, {})
                        if (Object.keys(this.businessUnitsWithNegativePrice).length > 0) {
                            return
                        }
                    }

                    this.canAdd = false
                    await this.$http.put(`/api/client/v1/products/${this.addObject.id}`, payload)
                    this.$emit('itemAdded')
                    this.$printSuccess(this.$t('print.success.edit'))
                    this.addObject = {
                        name: '',
                        sale_price: '',
                        color: '#545454',
                        vat_group_id: '',
                        product_category_id: '',
                        order_option_group_ids: [],
                        disabled: false,
                        additional_options: '',
                        course_menu_id: null,
                        additional_product_options: {},
                        no_predefined_price: false,
                        allow_negative_price: false
                    }
                    this.salePrice = ''
                    this.modalActive = false
                } catch (err) {
                    this.canAdd = true
                    Sentry.captureException(err)
                    if (err && err.response && err.response.data.error === 'price cannot be negative') {
                        this.$printWarning(this.$t('print.warning.price_cannot_be_negative'))
                    } else {
                        this.$printError(this.$t('print.error.on_edit'))
                    }
                }
            },
            getProductName(params) {
                if (params && this.allIngredients && this.allIngredients.length > 0) {
                    const tmp = this.allIngredients.find(x => x.id === params.id)

                    if (tmp) return tmp.name
                }

                return '/'
            },
            async remove(id) {
                try {
                    const confirmDelete = await this.$confirmDialog(this.$t('print.confirm.delete_row'))
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/client/v1/products/${id}`)
                    this.$printSuccess(this.$t('print.success.delete'))
                    await this.loadData()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            },
            changeSalePrice(val) {
                this.salePrice = JSON.parse(JSON.stringify(val))
                this.addObject.sale_price = Number(Number(Number(this.salePrice) * 1000000).toFixed(0))
            },
            changeNoPredefinedPrice(val) {
                if (!val) {
                    if (this.editing) {
                        this.showSalePrice = false
                    }
                    this.salePrice = ''
                    this.addObject.sale_price = 0
                    this.addObject.no_predefined_price = true
                } else {
                    this.addObject.no_predefined_price = false
                    this.addObject.allow_negative_price = false
                    if (this.editing) {
                        this.showSalePrice = true
                    }
                }
            },
            changeMaxDiscount(val) {
                this.maxDiscount = JSON.parse(JSON.stringify(val))
                this.addObject.additional_product_options.max_discount = Number(Number(Number(this.maxDiscount) * 100).toFixed(0))
            },
            changeMaxDiscountEnabled(val) {
                if (!val) {
                    this.maxDiscount = ''
                    this.addObject.additional_product_options.max_discount = ''
                    this.addObject.additional_product_options.max_discount_enabled = false
                } else {
                    this.addObject.additional_product_options.max_discount_enabled = true
                }
            },
            async  getCourseMenuConfiguration() {
                try {
                    if (this.$hasTierPermission(Tiers.Premium)) {
                        this.showLoader = true
                        const response = await this.$http.get('/api/client/v1/invoice_config/course_menu')
                        this.courseMenuConfiguration = response.data
                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }

            },
            async loadCourseMenu() {
                try {
                    if (this.$hasTierPermission(Tiers.Premium)) {
                        this.showLoader = true
                        const response = await this.$http.get('/api/client/v1/course_menu/')
                        this.course_menus = response.data ?? []
                    }

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            }
        },
        computed: {
            currentLocale() {
                return this.$store.getters['user/current_locale']
            },
            money_types() {
                return getMoneyTypes()
            },
            weight_types() {
                return getWeightTypes()
            },
            volume_types() {
                return getVolumeTypes()
            },
            piece_types() {
                return getPieceTypes()
            },
            columnDefs() {
                return [
                    {
                        headerName: this.$t('table_fields.name'),
                        field: 'name',
                        editable: false,
                        filter: true,
                        cellRenderer: (params) => this.getProductName(params.data)
                    },
                    {
                        headerName: this.$t('table_fields.quantity'),
                        field: 'quantity',
                        filter: true,
                        editable: false,
                        cellRenderer: (params) => this.getConvertedQuantity(params.data)
                    },
                    {
                        headerName: this.$t('table_fields.actions'),
                        editable: false,
                        filter: false,
                        sortable: false,
                        colId: 'actions',
                        cellRenderer: 'btnCellRenderer',
                        cellRendererParams: () => {
                            return {button: 'deleteBtn', noDelete: null}
                        },
                        maxWidth: 100,
                        minWidth: 100
                    }
                ]
            },
            Tiers() {
                return Tiers
            },
            getTitles() {
                const titles = [{title: this.getTabTitle}, {title: this.$t('keys.advanced')}]
                if (this.$hasTierPermission(Tiers.Standard, Tiers.Premium)) {
                    titles.splice(1, 0, {title: this.$t('general.ingredients')})
                }
                return titles
            },
            //used to set the max number of decimals for selected amount (conversion reasons check addIngredient() function)
            getQuantityRules() {
                if (this.selected_ingredient && this.selected_amount_type !== null) {
                    if (this.selected_amount_type.name === PackagingUnit.Gram || this.selected_amount_type.name === PackagingUnit.Milliliter) {
                        return 'required|min_value:1|decimalCustom:0|mustBePositive:true|min_number:0'
                    } else if (this.selected_amount_type.name === PackagingUnit.Decagram || this.selected_amount_type.name === PackagingUnit.Decilitre || this.selected_amount_type.name === PackagingUnit.Piece) {
                        return 'required|decimalCustom:2|mustBePositive:true|min_number:0.0'
                    } else if (this.selected_amount_type.name === PackagingUnit.Kilogram || this.selected_amount_type.name === PackagingUnit.Liter) {
                        return 'required|decimalCustom:3|mustBePositive:true|min_number:0'
                    } else if (this.selected_amount_type.name === PackagingUnit.Euro) {
                        return 'required|decimalCustom:6|mustBePositive:true|min_number:0'
                    }

                    return 'required|min_value:1|decimalCustom:0|mustBePositive:true|min_number:0'
                } else {
                    return ''
                }
            },
            formatPrice() {
                return `${this.$numberRounding(this.salePrice, 2)}`
            },
            getTabTitle() {
                if (this.addObject.name) {
                    if (this.addObject.name !== '') {
                        return this.addObject.name
                    }
                }

                return this.$t('keys.products.product')
            }
        },
        async mounted() {
            if (this.$hasTierPermission(Tiers.Premium)) {
                await this.getCourseMenuConfiguration()
                await this.loadCourseMenu()
            }

            this.newIngredientsFiltered = this.ingredients

        }
    }
</script>
<style scoped>
.disabledIngredient {
  color: red;
  cursor: not-allowed !important;
}

.dark-layout .cardBackground{
  background: #494949;
}

.light-layout .cardBackground{
  background: rgba(247, 247, 247, 0.97) !important;
}

.dark-layout .checkboxPrice .input-group-text {
    background-color: #2a2a2a!important;
    border-color: #2a2a2a!important;
}

.light-layout .checkboxPrice .input-group-text{
    background-color: white!important;
    border-color: #d8d6de!important;
}

 .checkboxPrice .input-group-text{
    border-radius: 0.357rem 0 0 0.357rem;
     height: 2.714rem;
 }

 .inputPrice input{
     border-radius: 0!important;
 }
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

  .productsModal .modal-dialog .modal-content {
      max-height: 90vh !important;
      height: 90vh !important;
  }
</style>